var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    { attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 18 18" } },
    [
      _c("g", { attrs: { id: "Capa_2", "data-name": "Capa 2" } }, [
        _c("g", { attrs: { id: "Capa_1-2", "data-name": "Capa 1" } }, [
          _c("rect", {
            staticClass: "fill-current",
            attrs: {
              x: "6.56",
              y: "2.66",
              width: "2.99",
              height: "2.17",
              rx: "0.29",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M6.95,4.66h.77a0,0,0,0,1,0,0V5.75A.29.29,0,0,1,7.42,6H7.24a.29.29,0,0,\n      1-.29-.29V4.67A0,0,0,0,1,6.95,4.66Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M11.21,5.52,10,4.91a.29.29,0,0,1-.14-.26V2.85A.27.27,0,0,1,10,2.59L11.21,\n      2c.11-.05.23,0,.23.19V5.34C11.44,5.48,11.32,5.58,11.21,5.52Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M7.43,4l-.07.29H7.09l.34-1.12h.34l.34,1.12H7.84L7.75,4Zm.29-.19-.07-.24c0-.06,\n      0-.14-.06-.21h0c0,.07,0,.15,0,.21l-.07.24Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M9,3.15v.93a1.85,1.85,0,0,0,0,.25H8.79V4.21h0a.28.28,0,0,1-.25.13.37.37,0,0,\n      1-.35-.41.39.39,0,0,1,.36-.43.25.25,0,0,1,.22.09h0V3.15Zm-.25.71v0a.16.16,0,0,0-.15-.12c-.11,\n      0-.17.1-.17.23s.06.22.17.22A.14.14,0,0,0,8.75,4a.11.11,0,0,0,0-.06Z",
            },
          }),
          _c("rect", {
            staticClass: "fill-current",
            attrs: {
              x: "6.56",
              y: "12.66",
              width: "2.99",
              height: "2.17",
              rx: "0.29",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M6.95,14.66h.77a0,0,0,0,1,0,0v1.09a.29.29,0,0,1-.29.29H7.24a.29.29,0,0,\n      1-.29-.29V14.67A0,0,0,0,1,6.95,14.66Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M11.21,15.52,10,14.91a.29.29,0,0,1-.14-.26v-1.8a.27.27,0,0,1,\n      .14-.26l1.2-.62c.11-.05.23,0,.23.19v3.18C11.44,15.48,11.32,15.58,11.21,15.52Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M7.43,14l-.07.29H7.09l.34-1.12h.34l.34,1.12H7.84L7.75,14Zm.29-.19-.07-.24c0-.06,\n      0-.14-.06-.21h0c0,.07,0,.15,0,.21l-.07.24Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M9,13.15v.93a1.85,1.85,0,0,0,0,.25H8.79v-.12h0a.28.28,0,0,1-.25.13.37.37,0,0,\n      1-.35-.41.39.39,0,0,1,.36-.43.25.25,0,0,1,.22.09h0v-.44Zm-.25.71v0a.16.16,0,0,0-.15-.12c-.11,\n      0-.17.1-.17.23s.06.22.17.22A.14.14,0,0,0,8.75,14a.11.11,0,0,0,0-.06Z",
            },
          }),
          _c("rect", {
            staticClass: "fill-current",
            attrs: {
              x: "1.37",
              y: "7.66",
              width: "2.99",
              height: "2.17",
              rx: "0.29",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M1.76,9.66h.77a0,0,0,0,1,0,0v1.09a.29.29,0,0,1-.29.29H2.05a.29.29,0,0,\n      1-.29-.29V9.67A0,0,0,0,1,1.76,9.66Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M6,10.52l-1.2-.61a.31.31,0,0,1-.14-.26V7.85a.29.29,0,0,1,.14-.26L6,7c.11,0,\n      .23,0,.23.19v3.18C6.25,10.48,6.13,10.58,6,10.52Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M2.25,9l-.08.29H1.91l.34-1.12h.33l.34,1.12H2.65L2.57,9Zm.28-.19-.07-.24a1.29,\n      1.29,0,0,1,0-.21h0a1.29,1.29,0,0,1,0,.21l-.07.24Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M3.82,8.15v.93c0,.09,0,.19,0,.25H3.61V9.21h0a.28.28,0,0,1-.25.13A.37.37,0,0,1,\n      3,8.93a.39.39,0,0,1,.37-.43.23.23,0,0,1,.21.09h0V8.15Zm-.25.71v0a.15.15,0,0,0-.15-.12c-.11,\n      0-.17.1-.17.23s.07.22.17.22A.16.16,0,0,0,3.57,9V8.86Z",
            },
          }),
          _c("rect", {
            staticClass: "fill-current",
            attrs: {
              x: "11.75",
              y: "7.66",
              width: "2.99",
              height: "2.17",
              rx: "0.29",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M12.14,9.66h.77a0,0,0,0,1,0,0v1.09a.29.29,0,0,1-.29.29h-.18a.29.29,\n      0,0,1-.29-.29V9.67A0,0,0,0,1,12.14,9.66Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M16.4,10.52l-1.2-.61a.29.29,0,0,1-.14-.26V7.85a.27.27,0,0,1,.14-.26L16.4,\n      7c.1,0,.23,0,.23.19v3.18C16.63,10.48,16.5,10.58,16.4,10.52Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M12.62,9l-.08.29h-.26l.34-1.12H13l.35,1.12H13L12.94,9Zm.28-.19-.07-.24a1.29,\n      1.29,0,0,1-.05-.21h0a1.29,1.29,0,0,1,0,.21l-.06.24Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M14.2,8.15V9.33H14V9.21h0a.29.29,0,0,1-.26.13.36.36,0,0,1-.34-.41.39.39,0,0,1,\n      .36-.43.22.22,0,0,1,.21.09h0V8.15ZM14,8.86a.43.43,0,0,1,0,0,.15.15,0,0,0-.14-.12c-.12,\n      0-.18.1-.18.23s.07.22.17.22A.15.15,0,0,0,13.94,9S14,9,14,9Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: { d: "M3.2,7A6.19,6.19,0,0,1,5.26,4.14" },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: { d: "M14.85,7a6.19,6.19,0,0,0-2.06-2.86" },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: { d: "M14.69,11.29a6,6,0,0,1-2,2.6" },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: { d: "M3.37,11.29a6,6,0,0,0,2,2.6" },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }